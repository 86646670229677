<template>
  <div>
    <v-app-bar elevation="4">
      <v-img
        :src="require('@/assets/images/logos/logo-small.png')"
        max-width="130px"
        alt="logo"
        contain
        class="me-3"
      ></v-img>
    </v-app-bar>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title style="width: 100%; display: block; text-align: center" class="align-center justify-center">
            <router-link style="" to="/" class="align-center">
              <h2 class="text-2xl font-weight-semibold">SIDIKBANG TEGAL</h2>
              <h5>
                SISTEM DIGITALISASI <br />Pendidikan, Pelatihan dan Pengembangan<br />RSUD dr SOESELO KABUPATEN TEGAL
              </h5>
            </router-link>
          </v-card-title>

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="email"
                outlined
                label="Email: "
                dense
                placeholder="xxx@xxx.com"
                hide-details
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="nama"
                outlined
                label="Nama lengkap :"
                dense
                placeholder="xxx"
                hide-details
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="nip"
                outlined
                label="NIP :"
                dense
                placeholder="xxx"
                hide-details
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="hp"
                outlined
                label="No HP :"
                dense
                placeholder="xxx"
                hide-details
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="password"
                outlined
                dense
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Password:"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
                class="mb-2"
              ></v-text-field>
              <v-text-field
                v-model="repeatPassword"
                outlined
                dense
                :type="isRepeatPasswordVisible ? 'text' : 'password'"
                label="Repeat Password"
                placeholder="············"
                :append-icon="isRepeatPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isRepeatPasswordVisible = !isRepeatPasswordVisible"
                class="mb-2"
              ></v-text-field>

              <VueRecaptcha
                :sitekey="siteKey"
                :load-recaptcha-script="true"
                @verify="handleSuccess"
                @error="handleError"
              >
              </VueRecaptcha>
              <v-btn block color="primary" class="mt-2" @click="register()" :loading="loading"> Register </v-btn>
            </v-form>
          </v-card-text>

          <!-- create new account  -->
          <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
            <span class="me-2"> Sudah punya akun? </span>
            <router-link :to="{ name: 'login' }"> Login </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const siteKey = process.env.VUE_APP_CAPTCHA_KEY
    const isPasswordVisible = ref(false)
    const isRepeatPasswordVisible = ref(false)
    const nama = ref('')
    const email = ref('')
    const nip = ref('')
    const hp = ref('')
    const password = ref('')
    const repeatPassword = ref('')

    return {
      siteKey,
      isPasswordVisible,
      isRepeatPasswordVisible,
      nama,
      email,
      nip,
      hp,
      password,
      repeatPassword,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    loading: false,
    recaptcha: '',
  }),
  methods: {
    handleError() {},
    handleSuccess(response) {
      this.recaptcha = response
    },
    register() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('nama', this.nama)
      fmData.append('email', this.email)
      fmData.append('nip', this.nip)
      fmData.append('hp', this.hp)
      fmData.append('password', this.password)
      fmData.append('repeatPassword', this.repeatPassword)
      fmData.append('recaptcha', this.recaptcha)
      axios
        .post(`${apiRoot}/Authentication/register`, fmData)
        .then(response => {
          if (response.data.code === 200) {
            alert(response.data.message)
            this.$router.push({ name: 'login' })
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>
